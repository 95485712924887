import { modules } from "~/config/common/modules.json"

interface BreadcrumbItem {
  label: string;
  href: string;
  isCurrent: boolean;
}

const useBreadcrumbs = () => {
  const router = useRouter();
  const route = useRoute();

  /**
   * Checks if a path segment has a valid route or is a valid module
   * @param path - The path to check
   * @param fullPath - The complete path up to this segment
   */
  const hasValidRoute = (path: string, fullPath: string): boolean => {
    if (modules.includes(path)) {
      return true;
    }

    try {
      const matchedRoute = router.resolve(fullPath);
      return matchedRoute.matched.length > 0 && 
             matchedRoute.matched.some(match => match.components?.default);
    } catch {
      return false;
    }
  };

  const breadcrumbs = computed<BreadcrumbItem[]>(() => {
    const pathSegments = route.path.split("/").filter(Boolean);
    const validBreadcrumbs: BreadcrumbItem[] = [];
    let currentPath = "";

    if (pathSegments.length > 0) {
      validBreadcrumbs.push({
        label: "Dashboard",
        href: "/dashboard",
        isCurrent: pathSegments.length === 0,
      });
    }
    
    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];
      currentPath = `/${pathSegments.slice(0, i + 1).join("/")}`;
      
      // Skip processing if the segment is 'index'
      if (segment === "index") continue;

      // Check if this segment should be included
      if (hasValidRoute(segment, currentPath)) {
        const label = segment.charAt(0).toUpperCase() + segment.slice(1);
        validBreadcrumbs.push({
          label,
          href: currentPath,
          isCurrent: i === pathSegments.length - 1,
        });
      }
    }

    return validBreadcrumbs;
  });

  return {
    breadcrumbs,
  };
};

export default useBreadcrumbs;
